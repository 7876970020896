import React, { useState, useEffect } from 'react';
import './sidebar.css';

const Sidebar = ({ isOpen, toggleSidebar, setActiveContent }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeItem, setActiveItem] = useState('spot');
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleItemClick = (item) => {
    setActiveItem(item);
    setActiveContent(item);
    if (isMobile) {
      toggleSidebar();
    }
  };

  const toggleExpand = (item) => {
    setExpandedItems(prev => ({ ...prev, [item]: !prev[item] }));
  };

  return (
    <>
      {isMobile && (
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          ☰
        </button>
      )}
      <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h3 style={{color:'#f7a600'}}>SELECT A BOT</h3>
        </div>
        <ul className="sidebar-content">
          <li>
            <button onClick={() => handleItemClick('spot')} className={activeItem === 'spot' ? 'active' : ''}>Spot</button>
          </li>
          <li>
            <button onClick={() => toggleExpand('futures')} className={activeItem.startsWith('futures') ? 'active' : ''}>
              Futures {expandedItems.futures ? '▼' : '►'}
            </button>
            {expandedItems.futures && (
              <ul>
                <li>
                  <button onClick={() => handleItemClick('futures-long-grid')} className={activeItem === 'futures-long-grid' ? 'active' : ''}>Long Grid</button>
                </li>
                <li>
                  <button onClick={() => handleItemClick('futures-short-grid')} className={activeItem === 'futures-short-grid' ? 'active' : ''}>Short Grid</button>
                </li>
              </ul>
            )}
          </li>
          <li>
              <button onClick={() => handleItemClick('simulate')} className={activeItem === 'simulate' ? 'active' : ''}>Simulate</button>
          </li>
          <li>
            <button onClick={() => handleItemClick('binary')} className={activeItem === 'binary' ? 'active' : ''}>Binary Bots</button>
          </li>
          <li>
            <button onClick={() => handleItemClick('forex')} className={activeItem === 'forex' ? 'active' : ''}>Forex Bots</button>
          </li>
          <li>
            <button onClick={() => handleItemClick('api-management')} className={activeItem === 'api-management' ? 'active' : ''}>API Management</button>
          </li>
          <li>
            <button onClick={() => handleItemClick('feedback')} className={activeItem === 'feedback' ? 'active' : ''}>Feedback</button>
          </li>
        </ul>
      </nav>
      {isMobile && isOpen && <div className="sidebar-backdrop" onClick={toggleSidebar}></div>}
    </>
  );
};

export default Sidebar;