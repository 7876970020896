import axiosInstance from './axiosConfig';
const register = (username, password) => {
  return axiosInstance.post('/users/register', {
    username,
    password,
    password2: password
  })
  .then(response => {
    return response.data;
  })
  .catch(error => {
    throw error.response?.data || error.message || 'An error occurred during registration';
  });
};

const login = (username, password) => {
  return axiosInstance.post('/users/login', { username, password })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('isLoggedIn', 'true');
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('token');
  return axiosInstance.post('/users/logout');
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const isLoggedIn = () => {
  return localStorage.getItem('isLoggedIn') === 'true';
};

const authService = {
  register,
  login,
  logout,
  getCurrentUser,
  isLoggedIn,
};

export default authService;
