import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import SpotForm from '../forms/SpotForm';
import LongGridForm from '../forms/LongGridForm';
import VirtualGridForm from '../forms/VirtualGridForm';
import ShortGridForm from '../forms/ShortGridForm';
import ActiveBotsList from '../update cards/ActiveBotsList';
import LogsDisplay from '../update cards/LogsDisplay';
import ApiManagement from '../Api-management/ApiManagement';
import './TradingBots.css';
import { useAuth } from '../../../AuthContext';
import axiosInstance from '../../services/axiosConfig';

const TradingBots = () => {
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 768);
  const [activeContent, setActiveContent] = useState('spot');
  const [activeBots, setActiveBots] = useState([]);
  const [selectedBotId, setSelectedBotId] = useState(null);
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      console.log('User is not logged in');
      return;
    }

    const fetchActiveBots = async () => {
      try {
        const response = await axiosInstance.get('/bot/active-bots');
        if (response.data.success) {
          console.log('Fetched bots:', response.data.bots);
          setActiveBots(response.data.bots);
          if (!selectedBotId && response.data.bots.some(bot => bot.isRunning)) {
            setSelectedBotId(response.data.bots.find(bot => bot.isRunning).botId);
          }
        } else {
          console.error('Failed to fetch bots:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching bots:', error);
      }
    };

    fetchActiveBots();
    const interval = setInterval(fetchActiveBots, 30000);

    return () => clearInterval(interval);
  }, [isLoggedIn, selectedBotId]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const renderContent = () => {
    switch (activeContent) {
      case 'spot':
        return (
          <div className="row h-100">
            <div className="col-lg-6 d-flex flex-column">
              <div className="mb-3">
                <SpotForm />
              </div>
              <div className="flex-grow-1 mb-3 mb-lg-0">
                <LogsDisplay
                  botId={selectedBotId}
                  runningBots={activeBots.filter(bot => bot.isRunning)}
                  onBotSelect={setSelectedBotId}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <ActiveBotsList
                activeBots={activeBots}
                selectedBotId={selectedBotId}
                onBotSelect={setSelectedBotId}
              />
            </div>
          </div>
        );
      case 'futures-long-grid':
        return (
          <div className="row h-100">
            <div className="col-lg-6 d-flex flex-column">
              <div className="mb-3">
                <LongGridForm onBotSelect={setSelectedBotId} />
              </div>
              <div className="flex-grow-1 mb-3 mb-lg-0">
                <LogsDisplay
                    botId={selectedBotId}
                    runningBots={activeBots.filter(bot => bot.isRunning)}
                    onBotSelect={setSelectedBotId}
                  />
              </div>
            </div>
            <div className="col-lg-6">
              <ActiveBotsList
                activeBots={activeBots}
                selectedBotId={selectedBotId}
                onBotSelect={setSelectedBotId}
              />
            </div>
          </div>
        );
      case 'futures-short-grid':
        return (
          <div className="row h-100">
            <div className="col-lg-6 d-flex flex-column">
              <div className="mb-3">
                <ShortGridForm />
              </div>
              <div className="flex-grow-1 mb-3 mb-lg-0">
                <LogsDisplay
                  botId={selectedBotId}
                  runningBots={activeBots.filter(bot => bot.isRunning)}
                  onBotSelect={setSelectedBotId}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <ActiveBotsList
                activeBots={activeBots}
                selectedBotId={selectedBotId}
                onBotSelect={setSelectedBotId}
              />
            </div>
          </div>
        );
        case 'simulate':
        return (
          <div className="row h-100">
            <div className="col-lg-6 d-flex flex-column">
              <div className="mb-3">
                <VirtualGridForm onBotSelect={setSelectedBotId} />
              </div>
              <div className="flex-grow-1 mb-3 mb-lg-0">
                <LogsDisplay
                    botId={selectedBotId}
                    runningBots={activeBots.filter(bot => bot.isRunning)}
                    onBotSelect={setSelectedBotId}
                  />
              </div>
            </div>
            <div className="col-lg-6">
              <ActiveBotsList
                activeBots={activeBots}
                selectedBotId={selectedBotId}
                onBotSelect={setSelectedBotId}
              />
            </div>
          </div>
        );
      case 'binary':
        return <div className="coming-soon">Binary Bots Content (Coming Soon)</div>;
      case 'forex':
        return <div className="coming-soon">Forex Bots Content (Coming Soon)</div>;
      case 'api-management':
        return <ApiManagement />;
      case 'feedback':
        return <div className="coming-soon">Feedback Content (Coming Soon)</div>;
      default:
        return <div className="coming-soon">Select an option from the sidebar</div>;
    }
  };

  return (
    <div className="trading-bots-container d-flex">
      <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} setActiveContent={setActiveContent} />
      <div className={`main-content flex-grow-1 ${sidebarOpen ? '' : 'sidebar-closed'}`}>
        {renderContent()}
      </div>
    </div>
  );
};

export default TradingBots;
