import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import './Scrollbar.css';

const Scrollbar = forwardRef(({ children, className = '', ...props }, ref) => {
  const scrollContentRef = useRef(null);
  const scrollTrackRef = useRef(null);
  const scrollThumbRef = useRef(null);
  const observer = useRef(null);
  const mutationObserver = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollToBottom: () => {
      const scrollContent = scrollContentRef.current;
      if (scrollContent) {
        scrollContent.scrollTop = scrollContent.scrollHeight;
      }
    }
  }));

  useEffect(() => {
    const scrollContent = scrollContentRef.current;
    const scrollThumb = scrollThumbRef.current;

    const resizeObserver = new ResizeObserver(() => {
      updateScrollThumbHeight();
      updateScrollThumbPosition();
    });

    observer.current = resizeObserver;
    resizeObserver.observe(scrollContent);

    mutationObserver.current = new MutationObserver(() => {
      updateScrollThumbHeight();
      updateScrollThumbPosition();
    });

    mutationObserver.current.observe(scrollContent, { childList: true, subtree: true });

    const updateScrollThumbHeight = () => {
      const { clientHeight, scrollHeight } = scrollContent;
      const thumbHeight = (clientHeight / scrollHeight) * clientHeight;
      scrollThumb.style.height = `${thumbHeight}px`;
    };

    const updateScrollThumbPosition = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollContent;
      const thumbTop = (scrollTop / scrollHeight) * clientHeight;
      scrollThumb.style.transform = `translateY(${thumbTop}px)`;
    };

    const handleScrollThumbDrag = (e) => {
      e.preventDefault();
      const startY = e.clientY - scrollThumb.getBoundingClientRect().top;
      const scrollContentRect = scrollContent.getBoundingClientRect();

      const handleMouseMove = (e) => {
        const deltaY = e.clientY - scrollContentRect.top - startY;
        const percentageDelta = deltaY / scrollContentRect.height;
        scrollContent.scrollTop = percentageDelta * scrollContent.scrollHeight;
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };

    scrollContent.addEventListener('scroll', updateScrollThumbPosition);
    scrollThumb.addEventListener('mousedown', handleScrollThumbDrag);

    updateScrollThumbHeight();

    return () => {
      resizeObserver.unobserve(scrollContent);
      mutationObserver.current.disconnect();
      scrollContent.removeEventListener('scroll', updateScrollThumbPosition);
      scrollThumb.removeEventListener('mousedown', handleScrollThumbDrag);
    };
  }, []);

  return (
    <div className={`custom-scrollbar ${className}`} {...props}>
      <div className="scroll-content" ref={scrollContentRef}>
        {children}
      </div>
      <div className="scroll-track" ref={scrollTrackRef}>
        <div className="scroll-thumb" ref={scrollThumbRef}></div>
      </div>
    </div>
  );
});

export default Scrollbar;