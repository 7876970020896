import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { PersonCircle, List } from 'react-bootstrap-icons';
import { useAuth } from '../../AuthContext';
import VirtualBalance from './VirtualBalance';

function Header() {
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" expanded={expanded} className="py-2">
      <Container fluid>
        <Navbar.Brand as={Link} to="/" className="me-0">
          <h1 className="logo m-0">TradingHub </h1>
        </Navbar.Brand>

        <div className="d-flex align-items-center ms-auto order-lg-last">
          {isLoggedIn && <VirtualBalance />}

          <div className="ms-3 d-flex align-items-center">
            {isLoggedIn ? (
              <NavDropdown
                title={<PersonCircle size={20} />}
                id="user-dropdown"
                align="end"
              >
                <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <Nav.Link as={Link} to="/login" className="me-2">Log In</Nav.Link>
                <Nav.Link as={Link} to="/register" className="btn-primary">Sign Up</Nav.Link>
              </>
            )}
          </div>

          <Navbar.Toggle
            aria-controls="navbar-nav"
            onClick={() => setExpanded(!expanded)}
            className="ms-3"
          >
            <List size={24} />
          </Navbar.Toggle>
        </div>

        <Navbar.Collapse id="navbar-nav" className="order-lg-1">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/arbitrage">Arbitrage</Nav.Link>
            <Nav.Link as={Link} to="/tradingbots">TradingBots</Nav.Link>
            <Nav.Link as={Link} to="/strategies">Strategies</Nav.Link>
            <Nav.Link as={Link} to="/help">Help</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;