import React, { useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Google, Facebook } from 'react-bootstrap-icons';
import { useAuth } from '../../AuthContext';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(username, password);
      if (response.success) {
        navigate('/');
      } else {
        setError(response.message || 'Login failed');
      }
    } catch (err) {
      setError(err.message || 'An error occurred during login');
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', background: '#0c0e14' }}>
      <Card className="border-0 rounded-4" style={{ background: '#181A20', color: '#EAECEF', maxWidth: '400px', width: '100%' }}>
        <Card.Body className="p-4">
          <h3 className="text-center mb-4">Log in</h3>
          <p className="text-center mb-4">
            Don't have an account? <Link to="/register" className="text-primary">Sign up</Link>
          </p>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mb-3">
              Log in
            </Button>
          </Form>
          {error && <Alert variant="danger">{error}</Alert>}
          <div className="text-center mt-3">
            <Link to="/forgot-password" className="text-primary">Forgot password?</Link>
          </div>
          <hr />
          <p className="text-center">Or continue with</p>
          <div className="d-flex justify-content-center gap-2">
            <Button variant="outline-danger"><Google /></Button>
            <Button variant="outline-primary"><Facebook /></Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;