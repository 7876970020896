import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import './BotForm.css';
import { useAuth } from '../../../AuthContext';
import axiosInstance from '../../services/axiosConfig';
import BotConditionsModal from './BotConditionsModal';

const VirtualGridForm = ({ onBotSelect }) => {
  const [formData, setFormData] = useState({
    exchangeId: 'binance',
    symbol: '',
    leverage: '',
    quantity: '',
    percentageChangePerGrid: '',
    precision: '',
    maxGrids: '',
    gridDirection: 'long',
  });

  const [showConditionsModal, setShowConditionsModal] = useState(false);
  const [conditions, setConditions] = useState(null);
  const { isLoggedIn } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      console.log('User must be logged in to start a bot');
      return;
    }
    try {
      const botData = {
        ...formData,
        botType: 'virtual-grid',
        conditions // This will be null if conditions weren't set
      };
      console.log('Submitting form data:', botData);
      const response = await axiosInstance.post('/bot/start-bot', botData);
      if (response.data.success) {
        console.log(`Virtual Grid Bot started with ID: ${response.data.botId}`);
        if (onBotSelect) {
          onBotSelect(response.data.botId);
        }
      }
    } catch (error) {
      console.error('Error starting virtual grid bot:', error.response?.data || error.message);
    }
  };

  const handleConditionsSave = (newConditions) => {
    setConditions(newConditions);
    console.log('Conditions saved:', newConditions);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Card bg="dark" text="light" className="bot-form-card">
        <Card.Body className="p-3">
          <Card.Title as="h4" className="mb-3 text-center">Virtual</Card.Title>
          <Form onSubmit={handleSubmit} className="compact-form">
          <Row className="gx-2 gy-2">
            <Col md={6}>
              <Form.Group controlId="symbol">
                <Form.Label className="mb-1">Symbol:</Form.Label>
                <Form.Control size="sm" type="text" name="symbol" value={formData.symbol} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="leverage">
                <Form.Label className="mb-1">Leverage:</Form.Label>
                <Form.Control size="sm" type="number" name="leverage" value={formData.leverage} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="gx-2 gy-2 mt-2">
            <Col md={6}>
              <Form.Group controlId="amount">
                <Form.Label className="mb-1">Amount per Grid:</Form.Label>
                <Form.Control size="sm" type="number" name="quantity" value={formData.quantity} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="percentageChangePerGrid">
                <Form.Label className="mb-1">Percentage Change per Grid:</Form.Label>
                <Form.Control size="sm" type="number" name="percentageChangePerGrid" value={formData.percentageChangePerGrid} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="gx-2 gy-2 mt-2">
            <Col md={6}>
              <Form.Group controlId="precision">
                <Form.Label className="mb-1">Precision:</Form.Label>
                <Form.Control size="sm" type="number" name="precision" value={formData.precision} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="maxGrids">
                <Form.Label className="mb-1">Max no. of Grids:</Form.Label>
                <Form.Control size="sm" type="number" name="maxGrids" value={formData.maxGrids} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="gx-2 gy-2">
              <Col md={6}>
                <Form.Group controlId="gridDirection">
                  <Form.Label className="mb-1">Grid Direction:</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    name="gridDirection"
                    value={formData.gridDirection}
                    onChange={handleChange}
                  >
                    <option value="long">Long</option>
                    <option value="short">Short</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="moreSettings">
                  <Form.Label className="mb-1">Optional:</Form.Label>
                  <Button
                    variant="secondary"
                    size="sm"
                    className="w-100"
                    onClick={() => setShowConditionsModal(true)}
                  >
                    More Settings
                    {conditions && <span className="ms-2">✓</span>}
                  </Button>
                </Form.Group>
              </Col>
            </Row>

            <Button variant="warning" type="submit" className="w-100 mt-3">
              Start Virtual Grid Bot
            </Button>
          </Form>
        </Card.Body>
      </Card>

      <BotConditionsModal
        show={showConditionsModal}
        onHide={() => setShowConditionsModal(false)}
        onSave={handleConditionsSave}
        isEditing={false}
      />
    </>
  );
};

export default VirtualGridForm;