import React, { useState, useEffect, useCallback, useRef } from 'react';
import io from 'socket.io-client';
import Scrollbar from '../../scrollbar/Scrollbar';
import axiosConfig from "../../services/axiosConfig";
console.log(`here is your ${Scrollbar}`);

const LogsDisplay = ({ botId, runningBots = [], onBotSelect }) => {
  const [logs, setLogs] = useState({});
  const [selectedBotId, setSelectedBotId] = useState(botId);
  const scrollbarRef = useRef(null);

  const handleLog = useCallback((logData) => {
    setLogs(prevLogs => {
      const updatedLogs = {
        ...prevLogs,
        [logData.botId]: [...(prevLogs[logData.botId] || []), logData.message]
      };
      // Schedule scrollToBottom after the state update
      setTimeout(() => {
        if (scrollbarRef.current) {
          scrollbarRef.current.scrollToBottom();
        }
      }, 0);
      return updatedLogs;
    });
  }, []);

  useEffect(() => {
    const socket = io(axiosConfig.defaults.baseURL, {
      auth: {
        token: localStorage.getItem('token')
      }
    });

    socket.on('connect', () => {
      console.log('Socket connected for logs');
      if (Array.isArray(runningBots)) {
        runningBots.forEach(bot => {
          if (bot && bot.botId) {
            socket.emit('subscribeLogs', bot.botId);
          }
        });
      }
    });

    socket.on('log', handleLog);

    return () => {
      console.log('Cleaning up socket connection for logs');
      socket.off('log', handleLog);
      socket.disconnect();
    };
  }, [runningBots, handleLog]);

  useEffect(() => {
    if (botId) {
      setSelectedBotId(botId);
    }
  }, [botId]);

  const handleBotChange = (event) => {
    const newBotId = event.target.value;
    setSelectedBotId(newBotId);
    if (onBotSelect) {
      onBotSelect(newBotId);
    }
  };

  const logLineStyle = {
    padding: '6px 10px',
    backgroundColor: '#2c3e50',
    margin: '2px 0',
    borderRadius: '4px',
    wordWrap: 'break-word',
    fontSize: '1em',  // Slightly reduce font size
    lineHeight: '1.3',  // Tighten line height
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 0',  // Reduced vertical padding
    marginBottom: '8px',
  };

  const titleStyle = {
    margin: 0,
    fontSize: '1.3em',  // Slightly smaller title
  };

  const selectStyle = {
    padding: '2px 5px',
    fontSize: '0.9em',
    appearance: 'none',
    backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFF00%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right .7em top 50%',
    backgroundSize: '.65em auto',
    paddingRight: '1.5em',
  };

  return (
    <div className="card bg-dark text-light" style={{ maxWidth: '600px', height: '225px', margin: '0 auto' }}>
      <div className="card-body d-flex flex-column" style={{ height: '100%', padding: '10px' }}>
        <div style={headerStyle}>
          <h4 className="card-title" style={{...titleStyle,color: '#f7a600'}} >Bot Logs</h4>
          <select
            className="form-select bg-dark text-light"
            style={{ ...selectStyle, width: 'auto', minWidth: 'fit-content',color:'#f7a600' }}
            value={selectedBotId || ''}
            onChange={handleBotChange}
          >
            <option value="">Select a bot</option>
            {Array.isArray(runningBots) && runningBots.filter(bot => bot.isOwner).map(bot => (
              bot && bot.botId ? (
                <option key={bot.botId} value={bot.botId} >
                  Bot {bot.botId}
                </option>
              ) : null
            ))}
          </select>
        </div>
        <Scrollbar ref={scrollbarRef} style={{ flexGrow: 1, minHeight: 0 }}>
          <div className="logs-content">
            {selectedBotId && logs[selectedBotId] ? (
              runningBots.find(bot => bot.botId === selectedBotId && bot.isOwner) ? (
                logs[selectedBotId].map((log, index) => (
                  <p key={index} style={logLineStyle}>{log}</p>
                ))
              ) : (
                <p>No logs Available</p>
              )
            ) : (
              <p>No logs available for the selected bot.</p>
            )}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default LogsDisplay;
