import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import RightTab from './RightTab';
import './home.css';
import Scrollbar from '../scrollbar/Scrollbar';

function Home() {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
      <Scrollbar>
        <div className="bg-dark text-light min-vh-100">
            <Container fluid className="py-4">
              <Row>
                <Col lg={6} className="mb-4 mb-lg-0">
                  <h1 className="welcome-text text-warning mb-4">Welcome to TradingHub</h1>
                  <p className="lead-text mb-4">
                    TradingHub is an algorithm-based platform that helps you automate your trades and strategies.
                    In the world of cryptocurrencies, stocks, and forex, our advanced platform ensures you stay
                    ahead of the market with precision and efficiency.
                  </p>
                  <Form onSubmit={handleSubmit} className="mb-4">
                    <Row>
                      <Col sm={8} className="mb-2 mb-sm-0">
                        <Form.Control
                          type="text"
                          placeholder="Email/Phone number"
                          className="custom-input"
                        />
                      </Col>
                      <Col sm={4}>
                        <Button
                          variant="warning"
                          type="submit"
                          className="w-100 custom-button"
                        >
                          Sign Up
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col lg={6} className="d-flex justify-content-center align-items-center">
                  <RightTab />
                </Col>
              </Row>
            </Container>
        </div>
        </Scrollbar>
  );
}

export default Home;