import React, { useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Google, Facebook } from 'react-bootstrap-icons';
import authService from '../services/auth.service';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
    try {
      const response = await authService.register(username, password);
      if (response.success) {
        navigate('/login'); // Redirect to login page after successful registration
      } else {
        setError(response.message || 'Registration failed');
      }
    } catch (err) {
      setError(err.message || 'An error occurred during registration');
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', background: '#0c0e14' }}>
      <Card className="border-0 rounded-4" style={{ background: '#181A20', color: '#EAECEF', maxWidth: '400px', width: '100%' }}>
        <Card.Body className="p-4">
          <h3 className="text-center mb-4">Create an Account</h3>
          <p className="text-center mb-4">
            Already have an account? <Link to="/login" className="text-primary">Sign in</Link>
          </p>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 mb-3">
              Register
            </Button>
          </Form>
          {error && <Alert variant="danger">{error}</Alert>}
          <hr />
          <p className="text-center">Or register with</p>
          <div className="d-flex justify-content-center gap-2">
            <Button variant="outline-danger"><Google /></Button>
            <Button variant="outline-primary"><Facebook /></Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Register;