import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import './BotForm.css';
import ApiSelector from '../Api-management/form-apisconfig';
import { useAuth } from '../../../AuthContext';
import axiosInstance from '../../services/axiosConfig';

const ShortGridForm = ({onBotSelect }) => {
  const [formData, setFormData] = useState({
    exchangeId: 'binance',
    symbol: '',
    leverage: '',
    amount: '',
    percentageChangePerGrid: '',
    precision: '',
    maxGrids: '',
    account_type: 'Testnet',
    apiKey: '',
    apiSecret: ''
  });

  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      // Handle the case when the user is not logged in
      console.log('User is not logged in');
      // You might want to redirect to login page or show a message
    }
  }, [isLoggedIn]);

  const handleSubmit = async (e) => {
  e.preventDefault();
  if (!isLoggedIn) {
    console.log('User must be logged in to start a bot');
    return;
  }
  try {
    const botData = {
      ...formData,
      botType: 'short-grid'
    };
    console.log('Submitting form data:', botData);
    const response = await axiosInstance.post('/bot/start-bot', botData);
    if (response.data.success) {
      console.log(`Short Grid Bot started with ID: ${response.data.botId}`);
      if (onBotSelect) {
        onBotSelect(response.data.botId);
      }
    }
  } catch (error) {
    console.error('Error starting short grid bot:', error.response?.data || error.message);
  }
};


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleApiSelect = (selectedApi) => {
    setFormData({
      ...formData,
      apiKey: selectedApi.apiKey,
      apiSecret: selectedApi.apiSecret,
      exchangeId: selectedApi.exchange,
      account_type: selectedApi.environment
    });
  };

  return (
    <Card bg="dark" text="light" className="bot-form-card">
      <Card.Body className="p-3">
        <Card.Title as="h4" className="mb-3 text-center">Bot Configuration - Short Grid</Card.Title>
        <Form onSubmit={handleSubmit} className="compact-form">
          <Row className="gx-2 gy-2">
            <Col md={6}>
              <Form.Group controlId="symbol">
                <Form.Label className="mb-1">Symbol:</Form.Label>
                <Form.Control size="sm" type="text" name="symbol" value={formData.symbol} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="leverage">
                <Form.Label className="mb-1">Leverage:</Form.Label>
                <Form.Control size="sm" type="number" name="leverage" value={formData.leverage} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="gx-2 gy-2 mt-2">
            <Col md={6}>
              <Form.Group controlId="amount">
                <Form.Label className="mb-1">Amount per Grid:</Form.Label>
                <Form.Control size="sm" type="number" name="amount" value={formData.amount} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="percentageChangePerGrid">
                <Form.Label className="mb-1">Percentage Change per Grid:</Form.Label>
                <Form.Control size="sm" type="number" name="percentageChangePerGrid" value={formData.percentageChangePerGrid} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="gx-2 gy-2 mt-2">
            <Col md={6}>
              <Form.Group controlId="precision">
                <Form.Label className="mb-1">Precision:</Form.Label>
                <Form.Control size="sm" type="number" name="precision" value={formData.precision} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="maxGrids">
                <Form.Label className="mb-1">Max no. of Grids:</Form.Label>
                <Form.Control size="sm" type="number" name="maxGrids" value={formData.maxGrids} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="gx-2 gy-2">
            <Col md={6}>
              <Form.Group controlId="api">
                <ApiSelector onApiSelect={handleApiSelect} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="moreSettings">
                <Form.Label className="mb-1">Optional:</Form.Label>
                <Button variant="secondary" size="sm" className="w-100">More Settings</Button>
              </Form.Group>
            </Col>
          </Row>

          <Button variant="warning" type="submit" className="w-100 mt-3">
            Start Short Grid Bot
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ShortGridForm;