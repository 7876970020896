import React, { useState } from 'react';
import { Tab, Nav, ListGroup } from 'react-bootstrap';
import './rightTab.css';

function RightTab() {
  const [activeTab, setActiveTab] = useState('popular');

  return (
    <div className="right-tab-container">
      <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
        <Nav variant="tabs" className="mb-3">
          <Nav.Item>
            <Nav.Link eventKey="popular">Popular</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="exchanges">Exchanges</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="popular">
            <ListGroup variant="flush">
              <ListGroup.Item>BTC</ListGroup.Item>
              <ListGroup.Item>ETH</ListGroup.Item>
              <ListGroup.Item>BNB</ListGroup.Item>
              <ListGroup.Item>XRP</ListGroup.Item>
            </ListGroup>
          </Tab.Pane>
          <Tab.Pane eventKey="exchanges">
            <ListGroup variant="flush">
              <ListGroup.Item>Binance</ListGroup.Item>
              <ListGroup.Item>Bybit</ListGroup.Item>
              <ListGroup.Item>Kucoin</ListGroup.Item>
              <ListGroup.Item>Mexc</ListGroup.Item>
            </ListGroup>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default RightTab;